<template>
  <div class="notify-intro">
    <template v-if="oneSize">
      <p class="notify-title">{{ $t('out-of-stock') }}</p>
      <p class="notify-subtitle">{{ $t('notify-me') }}</p>
    </template>
    <div class="block-notify-me">
      <div class="description-intro">
        <p class="mb-0">{{ $t('form-notify-me.email-notify-intro') }}</p>
      </div>
      <div class="block-email">
        <b-form ref="notify-form" data-vv-scope="form-notifyme"  @submit.prevent="handleSubmit" novalidate>
          <b-form-group>
            <base-input
            type="email"
            name="notify-email"
            v-model="email"
            :placeholder="$t('form-notify-me.enter-your-email')"
            class="input-email"
            v-validate="'required|email'"
            :validations="[{
                condition: errors.has('form-notifyme.notify-email'),
                text: errors.first('form-notifyme.notify-email')
            }]">
            </base-input>
          </b-form-group>
          <b-form-group>
            <base-check-box
              class="email-privacy-checbox"
              id="email-privacy"
              v-validate="'required:true'"
              v-model="formCheckbox"
              :use_prevent="false"
              name="email-privacy"
              :label="labelLinkPrivacy"
              :validations="[{
                  condition: errors.has('form-notifyme.email-privacy'),
                  text: errors.first('form-notifyme.email-privacy')
              }]"
            >
            </base-check-box>
          </b-form-group>
          <div class="block-actions">
            <div class="block-actions__notify-cta">
              <button type="submit" class="btn btn-primary w-100">
                {{$t('form-notify-me.submit-button')}}
              </button>
            </div>
          </div>
        </b-form>
        <div class="block-actions__try-me-cta" v-if="showBoutique">
          <button class="btn btn-secondary full-width" @click.prevent="$parent.openBoutique()">
            {{$t('find in boutique')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from '@/modules/notifications'
import BaseInput from '@/components/Form/BaseInput'
import BaseCheckBox from '@/components/Form/BaseCheckBox'
import Logger from '@/services/Logger'
import { pathTranslate } from '@/helpers/routeHelpers'

export default {
  inject: ['$validator'],
  name: 'NotifyMe',
  props: {
    product: {
      type: Object,
      required: true
    },
    productSelected: {
      type: Array | Object,
      required: true
    },
    variant: {
      type: Object,
      default: null
    },
    addToWishlist: {
      typw: Boolean,
      default: false
    },
    showBoutique: {
      typw: Boolean,
      default: true
    }
  },
  data () {
    return {
      email: this.$store.state.user.current ? this.$store.state.user.current.email : '',
      formCheckbox: false
    }
  },
  computed: {
    oneSize () {
      return this.product && this.product.one_size === 1
    },
    getProductByType () {
      // return product if it is array
      if (Array.isArray(this.productSelected)) {
        if (this.productSelected.length !== 1) {
          console.error('Only accept single product')
        }
        return this.productSelected[0]
      }
      return this.productSelected
    },
    labelLinkPrivacy () {
      let linkPrivacy = this.$router.resolve({ name: 'genericTerms', params: { slug: pathTranslate({ path: 'privacy-policy' }), lang: this.$store.state.ui.storeViewCode } }).href
      return this.$t('termsNotifyMe', { linkPrivacy })
    }
  },
  methods: {
    clearForm () {
      this.email = this.$store.state.user.current ? this.$store.state.user.current.email : ''
      this.formCheckbox = false
      this.$validator.reset()
    },
    handleSubmit (evt) {
      if (evt) {
        evt.preventDefault()
      }

      if (this.productId !== -1) {
        const _this = this
        this.$validator.validateAll('form-notifyme').then(isValid => {
          if (isValid && _this.getProductByType) {
            // actions here
            _this.$store.state.ui.loader = true
            _this.$store.dispatch('product/setNotify', { email: _this.email, productId: _this.getProductByType.id })
              .then(res => {
                // if user is logged add product to wishlist
                if (_this.$store.state.user.current && _this.addToWishlist) {
                  _this.addProductToWishlist()
                }
                toast.success(_this.$t('Request successful'))
                _this.clearForm()
              })
              .catch(() => {
                toast.error(_this.$t('GenericError'))
              })
              .finally(() => { _this.$store.state.ui.loader = false })
          }
        })
      } else {
        Logger.error('product id not valid, got -1')
      }
    },
    addProductToWishlist () {
      this.$store.dispatch('user/addToWishlist', { product: this.product, filters: this.variant })
        .catch(res => {
          const msg = res.body && res.body.result ? res.body.result : 'GenericError'
          // logger error here
          Logger.error(msg)
        })
    }
  },
  components: {
    BaseInput,
    BaseCheckBox
  }
}
</script>
